
// Fonts
@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,600);

// Variables
@import "dashboard/variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.1/css/all.min.css);

table.table.table-bare {
    background: transparent;

    th, td {
        border: none;
        padding: 0px 5px;
    }
}

.material-switch {
    display: inline-block;
    padding: 0px 14px;
}

.material-switch > input[type="checkbox"] {
    display: none;
}

.material-switch > label {
    cursor: pointer;
    height: 0px;
    position: relative;
    width: 40px;
}

.material-switch > label::before {
    background: rgb(0, 0, 0);
    box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.5);
    border-radius: 8px;
    content: '';
    height: 16px;
    margin-top: -8px;
    position:absolute;
    opacity: 0.1;
    transition: all 0.4s ease-in-out;
    width: 40px;
}
.material-switch > label::after {
    background: $brand-success;
    border-radius: 16px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
    content: '';
    height: 24px;
    left: -4px;
    margin-top: -8px;
    position: absolute;
    top: -4px;
    transition: all 0.3s ease-in-out;
    width: 24px;
}
.material-switch > input[type="checkbox"]:checked + label::before {
    background: inherit;
    opacity: 0.2;
}
.material-switch > input[type="checkbox"]:checked + label::after {
    background: $brand-danger;
    left: 20px;
}

.availability-switch {
    .toggle-label {
        font-size: smaller;
        padding-top: 2px;
    }

    .details {
        font-size: smaller;
        font-weight: 800;

        span {
            opacity: .7;
            font-weight: 400;
        }
    }
}

.js-minified {
    .sub-text {
        color: #bbb;
    }

    .js-toggle {
        text-decoration: none;
        color: #666;

        &:hover {
            text-decoration: none;
        }
    }
}
